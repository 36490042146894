.custom-uploader sup {
  color: #da1e28;
  font-weight: 500;
}

.custom-uploader .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.custom-uploader .label-wrapper {
  display: flex;
}

.custom-uploader .input-wrapper {
  position: relative;
  min-height: 100px;
  border: 1px solid #d5dade;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  font-weight: 500;
  color: #7c8d9f;
  flex-direction: column;
}

.custom-uploader .input-wrapper input {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  opacity: 0;
}

.custom-uploader {
  width: 100%;
}

.custom-uploader .upload-text {
  text-align: center;
}

.custom-uploader .upload-text u {
  color: #4163eb;
}

.custom-uploader .preview {
  width: 100%;
  padding: 8px;
  background: #f2f4f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.custom-uploader .preview img {
  height: 20px;
}

.custom-uploader .preview img:last-child {
  cursor: pointer;
}

.custom-uploader .preview .left-preview {
  display: flex;
}

.custom-uploader .preview .text {
  margin-left: 8px;
}

.custom-uploader .input-wrapper.active {
  border-style: dashed;
  border-width: 2px;
}
