.custom-text-area {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.custom-text-area textarea {
  width: 100%;
  font-size: 14px;
  border: 1px solid #d5dadf;
  border-radius: 5px;
  padding: 12px 10px;
}

.custom-text-area sup {
  color: #da1e28;
  font-weight: 500;
}

.custom-text-area .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}


.custom-text-area textarea:focus {
  outline: none;
}