.home-page .top-section {
  min-height: calc(100vh - 84px);
  display: flex;
  align-items: end;
  background-size: cover;
  position: relative;
}

.home-page .top-section .top-section-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgb(0 0 0);
  z-index: 1;
}

.home-page .top-section .top-section-wrapper {
  max-width: 1280px;
  margin: 0px auto;
  width: 100%;
  padding-bottom: 130px;
  position: relative;
  z-index: 2;
}

.home-page .top-section .top-section-wrapper .main-heading-wrapper {
  color: #fff;
}

.home-page
  .top-section
  .top-section-wrapper
  .main-heading-wrapper
  .main-heading {
  font-size: 80px;
  line-height: 1.1;
}

.home-page .top-section .top-section-wrapper .sub-heading-wrapper {
  font-size: 32px;
  color: #fff;
  margin-top: 60px;
}

.home-page .top-section .top-section-wrapper .link-section {
  display: flex;
  margin-top: 50px;
  align-items: center;
}

.home-page .top-section .top-section-wrapper .link-section .middle-text {
  margin-right: 8px;
  color: #fff;
}

.home-page .top-section .top-section-wrapper .link-section .explore-link {
  color: #fff;
}

@media screen and (max-width: 1280px) {
  .home-page
    .top-section
    .top-section-wrapper
    .main-heading-wrapper
    .main-heading {
    font-size: 48px;
  }

  .home-page .top-section .top-section-wrapper .sub-heading-wrapper {
    font-size: 24px;
    margin-top: 24px;
  }

  .home-page .top-section {
    align-items: center;
    padding: 0px 16px;
    min-height: calc(100vh - 58px);
  }

  .home-page .top-section .top-section-wrapper .link-section {
    font-weight: 500;
  }
}
