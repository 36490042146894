.about-us-page .culture-widget {
  background: #f8f9fa;
}

.about-us-page .culture-widget .culture-wrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: auto;
  padding: 80px 0px;
}

.about-us-page .culture-widget .culture-wrapper .culture-image {
  width: 50%;
  padding: 0px 60px 0px 0px;
}

.about-us-page .culture-widget .culture-wrapper .culture-image img {
  width: 100%;
}

.about-us-page .culture-widget .culture-wrapper .culture-overview {
  width: 50%;
  padding: 0px 0px 0px 60px;
}

.about-us-page .culture-widget .culture-wrapper .culture-overview .title {
  color: #7c8da0;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.2px;
}

.about-us-page .culture-widget .culture-wrapper .culture-overview .subtitle {
  margin: 16px 0px;
  font-size: 48px;
}

.about-us-page .culture-widget .culture-wrapper .culture-overview .description {
  line-height: 28px;
  font-weight: lighter;
  margin-bottom: 24px;
  color: #3d4147;
  font-size: 18px;
}

.about-us-page .culture-widget .culture-wrapper .bullet-point {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 15px;
}

.about-us-page .culture-widget .culture-wrapper .bullet-points .bullet-title {
  text-transform: uppercase;
}

.about-us-page
  .culture-widget
  .culture-wrapper
  .bullet-points
  .bullet-point
  img {
  width: 20px;
  margin-right: 16px;
}

@media screen and (max-width: 1280px) {
  .about-us-page .culture-widget .culture-wrapper {
    padding: 32px 16px;
    display: block;
  }

  .about-us-page .culture-widget .culture-wrapper .culture-image {
    width: 100%;
    padding: 0px;
  }

  .about-us-page .culture-widget .culture-wrapper .culture-overview {
    width: 100%;
    padding: 0px;
  }

  .about-us-page .culture-widget .culture-wrapper .culture-overview .title {
    margin-top: 16px;
  }

  .about-us-page .culture-widget .culture-wrapper .culture-overview .subtitle {
    font-size: 32px;
    font-weight: 500;
    line-height: 1;
    margin: 8px 0px;
  }
}
