.app-footer {
  background: #000;
  color: #fff;
}

.app-footer .pre-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin: auto;
}

.app-footer .pre-footer .main-heading-wrapper {
  margin-top: 60px;
}

.app-footer .pre-footer .main-heading-wrapper .main-heading {
  font-size: 50px;
}

.app-footer .pre-footer .sub-heading-wrapper .sub-heading {
  font-size: 20px;
  margin: 32px 0px;
}

.app-footer .footer-links {
  display: flex;
  max-width: 1280px;
  width: 100%;
  margin: auto;
  padding-top: 50px;
}

.app-footer .footer-links .logo {
  width: 32%;
  padding-right: 32px;
}

.app-footer .footer-links .logo img {
  width: 80px;
}

.app-footer .footer-links .footer-link-list {
  width: 33%;
}

.app-footer .footer-link-list .footer-link a {
  color: #fff;
  text-decoration: none;
}

.app-footer .footer-link-list .footer-link {
  padding: 8px;
}

.app-footer .footer-details-title {
  color: #7c8d9f;
  text-transform: uppercase;
  letter-spacing: 2.2px;
  margin-bottom: 8px;
}

.app-footer .office-address {
  margin-bottom: 24px;
}

.app-footer .detail-line {
  margin-bottom: 4px;
}

.app-footer .social-media-links {
  display: flex;
}

.app-footer .social-media-links img {
  width: 24px;
  margin-right: 8px;
}

.app-footer .contact-details {
  margin-bottom: 24px;
}

.app-footer .copyright {
  border-top: 1px solid #22272e;
  max-width: 1280px;
  margin: 50px auto 0px;
  padding: 18px 0px;
  display: flex;
  justify-content: space-between;
}

.app-footer .copyright .text {
  font-size: 12px;
  color: #b0b0b0;
}

.app-footer .copyright .privacy-policy a {
  text-decoration: none;
  color: #b0b0b0;
  font-size: 12px;
}

@media screen and (max-width: 1280px) {
  .app-footer .pre-footer .main-heading-wrapper {
    margin-top: 32px;
    padding: 0px 16px;
    text-align: center;
    font-weight: 500;
  }
  .app-footer .pre-footer .main-heading-wrapper .main-heading {
    display: inline;
    line-height: 58px;
    font-size: 48px;
  }
  .app-footer .pre-footer .main-heading-wrapper .main-heading::after {
    content: " ";
  }

  .app-footer .pre-footer .sub-heading-wrapper .sub-heading {
    margin: 32px 16px;
    text-align: center;
  }

  .app-footer .footer-links {
    display: block;
    padding: 64px 16px 32px;
  }
  .app-footer .footer-links .logo {
    width: 70%;
    padding: 0px;
    margin-bottom: 16px;
    display: block;
  }

  .app-footer .footer-links .footer-link-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }

  .app-footer .footer-link-list .footer-link {
    width: 50%;
    padding-left: 0px;
  }

  .app-footer .copyright {
    margin-top: 0px;
    padding: 32px 16px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .app-footer .office-address {
    margin-bottom: 32px;
  }

  .app-footer .contact-details {
    margin-bottom: 32px;
  }

  .app-footer .copyright .privacy-policy a {
    font-size: 18px;
    margin-bottom: 8px;
    display: block;
  }

  .app-footer .copyright .text {
    font-size: 18px;
  }

  .app-footer .footer-links .logo img {
    max-width: 80px;
  }
}
