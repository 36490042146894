.slide-item {
  width: 90%;
  display: inline-block;
  height: 100%;
  aspect-ratio: 2.35;
  background-size: cover;
  margin-right: 40px;
  position: relative;
}

.slide-item .overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.4);
}

.slide-item:last-child {
  margin-right: 0px;
}

.slide-item .slide-content {
  position: relative;
  z-index: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.slide-item .slide-content .icon {
  height: 70px;
  margin: 50px;
  width: auto;
}

.slide-item .slide-text-content {
  margin: 50px;
}

.slide-item .slide-text {
  margin: 32px 50px 0px;
}

.slide-item .slide-title {
  font-size: 48px;
}

.slide-item .slide-description {
  line-height: 24px;
  margin-top: 16px;
}

@media screen and (max-width: 1280px) {
  .slide-item .slide-content {
    width: 100%;
    display: block;
  }

  .slide-item {
    margin-right: 10px;
    width: 95%;
    background-size: cover;
    vertical-align: top;
  }

  .slide-item .slide-content .icon {
    height: 30px;
    margin: 16px;
  }

  .slide-item .slide-text {
    margin: 16px;
  }

  .slide-item .slide-title {
    font-size: 24px;
    font-weight: 500;
    width: 100%;
  }

  .slide-item .slide-description {
    margin-top: 8px;
  }

  .slide-item .slide-description .slide-description-text {
    display: inline;
    white-space: normal;
  }

  .slide-item .slide-description .slide-description-text::after {
    content: " ";
  }
}
