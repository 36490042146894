.career-details .link-section {
  box-shadow: 0px 0px 2px #898989;
}

.career-details .link-section .link-wrapper {
  max-width: 1280px;
  margin: auto;
  text-align: center;
}

.career-details .link-section .link-wrapper a {
  line-height: 42px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: #9aa5b6;
  margin-right: 24px;
  display: inline-block;
}

.career-details .link-section .link-wrapper a.active {
  color: #22272e;
  border-bottom: 2px solid #4163eb;
}

.career-details .link-section .link-wrapper a:last-child {
  pointer-events: none;
}
