.careers-page .top-section {
  min-height: calc(100vh - 84px);
  display: flex;
  align-items: end;
  background-size: contain !important;
  position: relative;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-color: #22272f !important;
}

.careers-page .top-section .top-section-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}

.careers-page .top-section .top-section-wrapper {
  max-width: 1280px;
  margin: 0px auto;
  width: 100%;
  padding: 60px 0px 60px 0px;
  position: relative;
  z-index: 2;
  display: flex;
}

.careers-page .top-section .top-section-wrapper .main-heading-wrapper {
  color: #22272e;
  font-weight: 500;
}

.careers-page
  .top-section
  .top-section-wrapper
  .main-heading-wrapper
  .main-heading {
  font-size: 80px;
  line-height: 1.1;
}

.careers-page .top-section .top-section-wrapper .sub-heading-wrapper {
  font-size: 32px;
  color: #22272e;
  margin-top: 60px;
}

.careers-page .top-section .top-section-wrapper .link-section {
  display: flex;
  margin-top: 50px;
  align-items: center;
}

.careers-page .top-section .top-section-wrapper .link-section .middle-text {
  margin-right: 8px;
  color: #22272e;
}

.careers-page .top-section .top-section-wrapper .link-section .explore-link {
  color: #22272e;
}

.careers-page .left-section {
  background-color: #fff;
  padding: 60px;
}

.careers-page .page-logo .page-title {
  color: #7c8d9f;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.2px;
}

.careers-page .page-logo {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.careers-page .title-decoration {
  width: 30px;
  height: 3px;
  background: rgb(66 99 235);
  margin-right: 16px;
}

@media screen and (max-width: 1280px) {
  .careers-page .top-section .top-section-wrapper {
    padding: 32px 16px;
  }

  .careers-page .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: -10% 0% !important;
  }

  .careers-page .left-section {
    padding: 32px 16px;
  }

  .careers-page .page-logo {
    margin-bottom: 8px;
  }

  .careers-page
    .top-section
    .top-section-wrapper
    .main-heading-wrapper
    .main-heading {
    display: inline;
    font-size: 48px;
    font-weight: 500;
  }

  .careers-page
    .top-section
    .top-section-wrapper
    .main-heading-wrapper
    .main-heading::after {
    content: " ";
  }

  .careers-page .top-section .top-section-wrapper .sub-heading-wrapper {
    margin-top: 16px;
    line-height: 28px;
  }

  .careers-page
    .top-section
    .top-section-wrapper
    .sub-heading-wrapper
    .sub-heading {
    display: inline;
    font-size: 24px;
  }

  .careers-page
    .top-section
    .top-section-wrapper
    .sub-heading-wrapper
    .sub-heading::after {
    content: " ";
  }
}
