.partner-section {
  padding: 60px 0px;
}

.partner-section .partner-section-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 90px;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.partner-section .partner-section-wrapper .left-section .title {
  font-size: 48px;
  color: #fff;
}

.partner-section .partner-section-wrapper .left-section .description {
  color: #fff;
  line-height: 30px;
  margin-bottom: 32px;
}

.partner-section .partner-section-wrapper .right-section {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
}

/* .partner-section .partner-section-wrapper .right-section .partner-wrapper-separator {
  content: '';
  position: absolute;
  right: -1.5px;
  background: lightgray;
  opacity: 0.5;
  width: 3px;
  height: 80px;
  top: 20px;
} */

.partner-section .partner-section-wrapper .right-section .partner {
  margin: 15px 20px;
}

.partner-section .partner-section-wrapper .right-section .partner .partner-image-div {
  height: 120px;
  width: 300px;
  background: white;
  border-radius: 7px;
  display: flex;
}

.partner-section .partner-section-wrapper .right-section .partner img {
  max-height: 100%;
  max-width: 100%;
  display: block;
  margin: auto;
}

@media screen and (max-width: 1280px) {
  .partner-section {
    padding: 16px;
  }
  .partner-section .partner-section-wrapper {
    padding: 24px;
  }

  .partner-section .partner-section-wrapper .left-section .description {
    margin-top: 0px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 700px) {
  .partner-section .right-section {
    flex-direction: column;
    flex-direction: row;
  }

  .partner-section .partner-section-wrapper .right-section .partner-wrapper {
    /* width: 50%; */
    /* display: inline-block; */
  }

  .partner-section .partner-section-wrapper .right-section .partner {
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 90%;
    height: unset;
  }

  .partner-section .partner-section-wrapper .right-section .partner .partner-image-div {
    width: 100%;
    border-radius: 15px;
    height: unset;
  }

  .partner-section .partner-section-wrapper .right-section .partner img {
    width: 100%;
    height: unset;
    max-width: 250px;
  }

  .partner-section .partner-section-wrapper .right-section .partner-wrapper:not(:last-child)::after {
    top: unset;
    bottom: -1.5px;
    width: 50% ;
    height: 3px;
    left: 0px;
    right: 0px;
    margin: auto;
    background: lightgray;
  }
}
