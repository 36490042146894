.work-list-section {
  max-width: 1280px;
  margin: auto;
  padding: 60px 0px 20px;
}

.work-list-section .work-list-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 40px;
}

.work-list-section .work-list-item .link-title {
  color: #3156ed;
  margin: 16px 0px;
}

.work-list-section .work-list-item .link-description {
  font-size: 38px;
  color: #22272e;
}

.work-list-section .work-list-item.small {
  width: calc(50% - 20px);
  margin-right: 40px;
  margin-bottom: 40px;
}

.work-list-section .work-list-item.small.no-margin-right {
  margin-right: 0px;
}

.work-list-section .work-list-item img {
  width: 100%;
}

.work-list-section .work-list-item.large {
  display: flex;
  align-items: end;
}

.work-list-section .work-list-item.large img {
  width: 75%;
}

.work-list-section .work-list-item.large .link-text-container {
  margin: 0px 0px 20px 40px;
}

@media screen and (max-width: 1280px) {
  .work-list-section {
    padding: 32px 16px;
  }

  .work-list-section .work-list-item.small {
    width: 100%;
    margin: 0px 0px 24px;
  }

  .work-list-section .work-list-item.large {
    display: block;
  }

  .work-list-section .work-list-item.large img {
    width: 100%;
  }

  .work-list-section .work-list-item .link-title {
    margin: 8px 0px;
    font-weight: 500;
  }

  .work-list-section .work-list-item .link-description {
    font-size: 24px;
    font-weight: 500;
  }

  .work-list-section .work-list-item.large .link-text-container {
    margin: 0px;
  }
}
