.custom-button {
  color: #fff;
  background-color: #4163eb;
  width: 100%;
  border: 0;
  padding: 12px 0px;
  font-size: 16px;
  cursor: pointer;
}

.custom-button.disabled {
  background-color: #b3c0f7;
}
