.service-page .service-widget .service-wrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  margin: auto;
  padding: 80px 0px;
}

.service-page .service-widget .service-wrapper.reverse {
  flex-direction: row-reverse;
}

.service-page .service-widget .service-wrapper .service-image {
  width: 50%;
  padding: 0px 60px 0px 0px;
}

.service-page .service-widget .service-wrapper.reverse .service-image {
  width: 50%;
  padding: 0px 0px 0px 60px;
}

.service-page .service-widget .service-wrapper .service-image img {
  width: 100%;
}

.service-page .service-widget .service-wrapper .service-overview {
  width: 50%;
  padding: 0px 0px 0px 60px;
}

.service-page .service-widget .service-wrapper .service-overview .title {
  color: rgb(66, 99, 235);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.2px;
}

.service-page .service-widget .service-wrapper .service-overview .subtitle {
  margin: 16px 0px;
  font-size: 36px;

  color: #22272e;
}

.service-page .service-widget .service-wrapper .bullet-point {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  margin-top: 36px;
}

.service-page .service-widget .service-wrapper .bullet-point .bullet-text {
  margin-bottom: 8px;
  font-weight: 500;
}

.service-page
  .service-widget
  .service-wrapper
  .bullet-point
  .bullet-description {
  line-height: 28px;
}

.service-page
  .service-widget
  .service-wrapper
  .bullet-points
  .bullet-point
  img {
  width: 20px;
  margin-right: 16px;
}

@media screen and (max-width: 1280px) {
  .service-page .service-widget .service-wrapper {
    padding: 32px 16px;
    display: block;
  }

  .service-page .service-widget .service-wrapper .service-image {
    width: 100%;
    padding: 0px;
  }

  .service-page .service-widget .service-wrapper.reverse .service-image {
    width: 100%;
    padding: 0px;
  }

  .service-page .service-widget .service-wrapper .service-overview {
    width: 100%;
    padding: 0px;
  }

  .service-page .service-widget .service-wrapper .service-overview .title {
    margin-top: 16px;
  }

  .service-page .service-widget .service-wrapper .service-overview .subtitle {
    margin: 8px 0px;
    font-size: 24px;
    font-weight: 500;
  }

  .service-page .service-widget .service-wrapper .bullet-point {
    margin-top: 24px;
  }
}
