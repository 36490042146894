.office-section {
  background: #f8f9fa;
  background-size: contain;
}

.office-section .office-section-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 60px 0px;
}

.office-section .office-section-wrapper .office-title {
  color: #7c8d9f;
  text-transform: uppercase;
  letter-spacing: 2.2px;
  margin-bottom: 24px;
  text-align: center;
}

.office-section .office-section-wrapper .office-description {
  text-align: center;
  font-size: 48px;
  margin-bottom: 24px;
}

.office-section .office-section-wrapper img {
  width: 100%;
}

.office-section .office-section-wrapper .office-address-wrapper {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.office-section .office-section-wrapper .office-address-wrapper .office-item {
  background: #fff;
  width: 32%;
  margin-bottom: 24px;
  margin-right: 24px;
  padding: 24px;
  border-radius: 10px;
}

.office-section
  .office-section-wrapper
  .office-address-wrapper
  .office-item:nth-child(3n + 3) {
  margin-right: 0px;
}

.office-section
  .office-section-wrapper
  .office-address-wrapper
  .office-item
  .office-decoration {
  background: rgb(66 99 235);
  width: 20px;
  height: 2px;
  margin-right: 8px;
}

.office-section
  .office-section-wrapper
  .office-address-wrapper
  .office-item
  .office-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.office-section
  .office-section-wrapper
  .office-address-wrapper
  .office-item
  .office-name {
  font-size: 20px;
  font-weight: 500;
}

.office-section
  .office-section-wrapper
  .office-address-wrapper
  .office-item
  .office-address {
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  color: #7c8d9f;
}

@media screen and (max-width: 1280px) {
  .office-section .office-section-wrapper {
    padding: 32px 16px;
  }
  .office-section .office-section-wrapper .office-title {
    margin-bottom: 8px;
  }

  .office-section .office-section-wrapper .office-description {
    font-size: 24px;
    font-weight: 500;
  }

  .office-section .office-section-wrapper .office-address-wrapper {
    margin-top: 16px;
    display: block;
  }

  .office-section .office-section-wrapper .office-address-wrapper .office-item {
    width: 100%;
    margin-bottom: 8px;
  }
}
