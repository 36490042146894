.custom-checkbox input[type="checkbox"] {
  margin: 0px 8px 0px 0px;
  cursor: pointer;
}

.custom-checkbox label {
  font-size: 14px;
}

.custom-checkbox {
  display: flex;
  align-items: baseline;
  margin-bottom: 16px;
}

.custom-checkbox label span {
  color: #da1e28;
}
