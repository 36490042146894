@font-face {
  font-family: "IBMPlexSans";
  src: url("./font/font-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./font/font-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./font/font-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "IBMPlexSans";
  src: url("./font/font-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "IBMPlexSans", "Segoe UI", "Roboto", "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #22272E;
}

* {
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}
