.home-page .trusted-section {
  background: #fff;
}

.home-page .trusted-section .trusted-section-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 60px 0px 0px;
}

.home-page .trusted-section .trusted-section-wrapper .title {
  letter-spacing: 2.2px;
  text-transform: uppercase;
  color: #7c8da0;
}

.home-page .trusted-section .logo-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}

.home-page .trusted-section .logo-list img {
  height: 40px;
  margin: 0px 32px 20px 0px;
}

.home-page .our-work-section {
  background: #f8f9fa;
  padding: 60px 0px;
}

.home-page .our-work-section .our-work-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: auto;
}

.home-page .our-work-section .our-work-wrapper .title {
  letter-spacing: 2.2px;
  text-transform: uppercase;
  color: #7c8da0;
}

.home-page .our-work-section .description-wrapper {
  margin: 16px 0px 16px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-page .our-work-section .description-wrapper .description {
  font-size: 48px;
}

.home-page .our-work-section .slides-container {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding: 0px calc((100vw - 1280px) / 2);
}

.home-page .our-work-section .slide-switcher {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.home-page .our-work-section .slide-switcher img {
  height: 30px;
  cursor: pointer;
}

.home-page .our-work-section .slide-switcher .slide-count {
  margin: 0px 16px;
}

.home-page .service-section {
  background: #fff;
}

.home-page .service-section .service-section-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 60px 0px;
}

.home-page .service-section .service-section-wrapper .title {
  letter-spacing: 2.2px;
  text-transform: uppercase;
  color: #7c8da0;
}

.home-page .service-section .service-section-wrapper .subtitle {
  margin: 16px 0px;
  font-size: 48px;
}

.home-page .service-section .service-section-wrapper .description {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 16px;
}

.home-page .service-section .service-section-wrapper .service-catalog {
  display: flex;
  justify-content: space-between;
}

.home-page
  .service-section
  .service-section-wrapper
  .service-catalog
  .service-item {
  width: 31%;
}

.home-page
  .service-section
  .service-section-wrapper
  .service-catalog
  .service-item
  .service-icon {
  width: 60px;
  height: auto;
  margin-bottom: 16px;
}

.home-page
  .service-section
  .service-section-wrapper
  .service-catalog
  .service-title {
  border-bottom: 2px solid rgb(220 225 229);
  padding-bottom: 4px;
  font-weight: 500;
}

.home-page
  .service-section
  .service-section-wrapper
  .service-catalog
  .service-item
  .text {
  border-bottom: 1px solid rgb(220 225 229);
  padding: 12px 0px;
}

@media screen and (max-width: 1280px) {
  .home-page .trusted-section .trusted-section-wrapper {
    padding: 24px 16px 12px;
  }

  .home-page .trusted-section .logo-list {
    margin-top: 16px;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .home-page .trusted-section .logo-list img {
    height: 60px;
  }

  .text {
    display: inline;
  }

  .text::after {
    content: " ";
  }

  .about-us-section .about-us-wrapper {
    padding: 12px 16px 24px;
    display: block;
  }

  .about-us-section .about-us-wrapper .title {
    font-size: 16px;
    margin-right: 0px;
    margin-bottom: 8px;
  }

  .about-us-section .about-us-wrapper .about-description {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .home-page .our-work-section {
    padding: 24px 16px 80px;
    position: relative;
  }

  .home-page .our-work-section .description-wrapper {
    display: block;
  }

  .home-page .our-work-section .description-wrapper .description {
    font-size: 24px;
  }

  .home-page .our-work-section .slide-switcher {
    position: absolute;
    bottom: 0px;
    margin-bottom: 32px;
  }

  .home-page .our-work-section .slides-container {
    width: 100vw;
    padding: 0px 16px;
    margin: 0px -16px;
    height: 450px;
  }

  .home-page .service-section .service-section-wrapper {
    padding: 24px 16px;
  }
  .home-page .service-section .service-section-wrapper .subtitle {
    font-size: 32px;
    margin: 8px 0px;
    font-weight: 500;
  }

  .home-page .service-section .service-section-wrapper .service-catalog {
    display: block;
  }
  .home-page
    .service-section
    .service-section-wrapper
    .service-catalog
    .service-item {
    width: 100%;
    margin-bottom: 32px;
  }
  .home-page
    .service-section
    .service-section-wrapper
    .service-catalog
    .service-item:last-child {
    margin-bottom: 0px;
  }

  .home-page
    .service-section
    .service-section-wrapper
    .service-catalog
    .service-item
    .text {
    display: block;
  }

  .home-page
    .service-section
    .service-section-wrapper
    .service-catalog
    .service-title {
    font-weight: 500;
  }

  .home-page .career-widget .career-wrapper {
    padding: 24px 16px;
    display: block;
  }
  .home-page .career-widget .career-wrapper .career-image {
    width: 100%;
    padding: 0px;
  }
  .home-page .career-widget .career-wrapper .career-overview {
    width: 100%;
    padding: 24px 0px 0px;
  }
  .home-page .career-widget .career-wrapper .career-overview .subtitle {
    font-size: 24px;
    margin: 8px 0px;
    font-weight: 500;
  }
}
