.career-details .overview-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 60px 0px;
  display: flex;
}

.career-details .overview-wrapper .left-section-wrapper {
  position: sticky;
  top: 120px;
  z-index: 1;
}

.career-details .overview-wrapper .left-section {
  width: 320px;
}

.career-details .overview-wrapper .right-section-wrapper {
  width: 75%;
}

.career-details .overview-wrapper .right-section {
  flex-grow: 1;
}

.career-details .overview-wrapper .left-section a {
  display: block;
  margin-bottom: 16px;
  text-decoration: none;
  color: #7c8d9f;
}

.career-details .overview-wrapper .left-section a.active {
  color: #4163eb;
}

.career-details .overview-wrapper .right-section .section-heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid #d5dadf;
  margin-bottom: 16px;
}

.career-details .overview-wrapper .right-section .section-paragraph {
  margin-bottom: 32px;
  line-height: 28px;
}

.career-details .overview-wrapper .right-section .bullet-points {
  padding-inline-start: 20px;
}

.career-details
  .overview-wrapper
  .right-section
  .bullet-points
  .bullet-point-item {
  line-height: 28px;
}

@media screen and (max-width: 1280px) {
  .career-details .overview-wrapper {
    padding-top: 32px;
    display: block;
  }
  .career-details .overview-wrapper .left-section {
    display: none;
  }

  .career-details .overview-wrapper .right-section-wrapper {
    width: 100%;
    padding: 0px 16px;
  }
}
