.custom-input {
  display: flex;
  flex-wrap: wrap;
}

.custom-input input {
  width: 100%;
  font-size: 14px;
  border: 1px solid #d5dadf;
  border-radius: 5px;
  padding: 12px 10px;
}

.custom-input sup {
  color: #da1e28;
  font-weight: 500;
}

.custom-input .label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.custom-input input:focus {
  outline: none;
}