
.home-page .career-widget {
  background: #f8f9fa;
}

.home-page .career-widget .career-wrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  align-items: center;
  margin: auto;
  padding: 80px 0px;
}

.home-page .career-widget .career-wrapper .career-image {
  width: 50%;
  padding: 0px 60px 0px 0px;
}

.home-page .career-widget .career-wrapper .career-image img {
  width: 100%;
}

.home-page .career-widget .career-wrapper .career-overview {
  width: 50%;
  padding: 0px 0px 0px 60px;
}

.home-page .career-widget .career-wrapper .career-overview .title {
  color: #7c8da0;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.2px;
}

.home-page .career-widget .career-wrapper .career-overview .subtitle {
  margin: 16px 0px;
  font-size: 48px;
}

.home-page .career-widget .career-wrapper .career-overview .description {
  line-height: 28px;
  font-weight: lighter;
  margin-bottom: 24px;
  color: #3d4147;
  font-size: 18px;
}

.home-page .career-widget .career-wrapper .bullet-point {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 15px;
}

.home-page .career-widget .career-wrapper .bullet-points .bullet-title {
  text-transform: uppercase;
  font-weight: 500;
}

.home-page .career-widget .career-wrapper .bullet-points .bullet-point img {
  width: 20px;
  margin-right: 16px;
}
