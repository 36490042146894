.career-details .top-section {
  min-height: 520px;
  position: relative;
  display: flex;
}

.career-details .top-section .overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.career-details .top-section .top-section-wrapper {
  max-width: 1280px;
  z-index: 1;
  color: #fff;
  position: relative;
  margin: auto;
  text-align: center;
}

.career-details .top-section .page-title {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2.2px;
  margin-bottom: 8px;
  font-size: 20px;
}

.career-details .top-section .page-subtitle {
  font-size: 64px;
  font-weight: 500;
}

.career-details .top-section .title-decoration {
  width: 50px;
  height: 5px;
  background: #4163eb;
  margin: 24px auto;
}

.career-details .top-section .main-heading-wrapper {
  font-size: 18px;
  width: 70%;
  margin: auto;
  line-height: 32px;
}

@media screen and (max-width: 1280px) {
  .career-details .top-section {
    min-height: calc(100vh - 58px);
    background-size: cover !important;
  }

  .career-details .top-section .top-section-wrapper {
    padding: 32px 16px;
  }

  .career-details .top-section .page-title {
    font-size: 16px;
  }

  .career-details .top-section .page-subtitle {
    font-size: 48px;
  }

  .career-details .top-section .title-decoration {
    margin: 16px auto;
  }

  .career-details .top-section .main-heading-wrapper {
    width: 100%;
  }
}
