.about-us-section .about-us-wrapper {
  padding: 60px 0px 0px;
}

.about-us-section .about-us-wrapper .title {
  letter-spacing: 2.2px;
  text-transform: uppercase;
  color: #7c8da0;
  margin-right: 90px;
}

.about-us-section .about-us-wrapper .about-description {
  font-size: 30px;
  font-weight: lighter;
  margin-bottom: 32px;
  color: #22272E;
}

.about-us-section .about-us-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: auto;
  display: flex;
  padding-bottom: 60px;
}
