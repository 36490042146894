.contact-us {
  display: flex;
  background: #fff;
  color: #000;
  text-decoration: none;
  padding: 16px;
  margin-right: 20px;
}

.contact-us img {
  height: 20px;
}

.contact-us .button-text {
  margin-right: 8px;
}

.contact-us.slide-link {
  background: transparent;
  color: rgb(255, 255, 255);
  margin: 50px;
  display: inline-flex;
  width: 139px;

  padding: 0px;
}

.contact-us.career-contact {
  background: #4263eb;
  color: #fff;
  margin: 20px 0px;
  display: inline-flex;
}

.contact-us.about-us-contact {
  background: #4263eb;
  color: #fff;
  margin: 20px 0px;
  display: inline-flex;
}

.contact-us.our-work-detail-contact {
  background: #4263eb;
  color: #fff;
  margin: 20px 20px 20px 0px;
  display: inline-flex;
}

.contact-us.our-work-contact {
  background: #f8f9fa;
  color: #22272e;

  margin: 20px 0px;
  display: inline-flex;
  padding-left: 0px;
}

.contact-us.our-services-contact {
  margin: 0px 0px 40px;
  width: 151px;
  border: 1px solid rgb(206 211 219);
}

.contact-us.transparent {
  background: transparent;
}

@media screen and (max-width: 1280px) {
  .contact-us {
    margin-right: 8px;
  }
  
  .contact-us.about-us-contact {
    margin: 0px;
  }

  .contact-us.our-work-contact {
    margin: 4px 0px;
  }

  .contact-us.slide-link {
    margin: 8px 16px 0px;
    font-weight: 500;
  }

  .contact-us.our-services-contact {
    margin: 0px 0px 32px;
  }
  .contact-us.career-contact {
    margin-bottom: 0px;
  }
}
