.application-section {
  padding: 60px 0px;
  background: #f2f4f6;
}

.application-section .application-form {
  max-width: 650px;
  margin: auto;
}

.application-section .form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #d5dadf;
  padding-bottom: 8px;
}

.application-section .form-header .clear-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.application-section .form-header .clear-wrapper img {
  height: 18px;
  width: auto;
}

.application-section .form-header .clear-text {
  font-size: 12px;
}

.application-section .form-header .form-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
}

.application-section .form-elements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
}

.application-section .form-elements .custom-input {
  margin-bottom: 16px;
}

.application-section .custom-checkbox {
  margin-top: 40px;
}

.application-section .custom-button {
  margin-top: 40px;
}

@media screen and (max-width: 1280px) {
  .application-section {
    padding: 32px 16px;
  }

  .application-section .form-header .form-title {
    font-size: 24px;
  }
}
