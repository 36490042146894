.app-header {
  background: #000;
  position: sticky;
  top: 0px;
  padding: 8px 0px;
  z-index: 100;
}

.app-header .app-header-container {
  margin: auto;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-header .app-header-container .left-section .logo img {
  width: 200px;
  filter: invert(1);
}

.app-header .app-header-container .left-section .hamburger-icon {
  display: none;
}

.app-header .app-header-container .right-section {
  display: flex;
}

.app-header .app-header-container .right-section .header-link {
  padding: 12px 16px;
  border-radius: 5px;
  margin-left: 8px;
}

.app-header .app-header-container .right-section .header-link {
  text-decoration: none;
  color: #fff;
}

.app-header .app-header-container .right-section .header-link.active,
.app-header .app-header-container .right-section .header-link:hover {
  background: #22272e;
}

@media screen and (max-width: 1280px) {
  .app-header .app-header-container {
    padding: 0px 16px;
    display: block;
  }

  .app-header .app-header-container .left-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .app-header .app-header-container .left-section .hamburger-icon {
    display: block;
    height: 25px;
    width: 25px;
  }

  .app-header .app-header-container .left-section .hamburger-icon svg {
    fill: white;
    width: 100%;
    height: 100%;
    background-color: #000;
  }

  .app-header .app-header-container .right-section {
    display: none;
    position: absolute;
    background: #000;
    left: 0px;
    right: 0px;
  }

  .app-header .app-header-container .right-section.visible {
    min-height: calc(100vh - 58px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .app-header .app-header-container .right-section .header-link {
    display: block;
    text-align: center;
    margin-left: 0px;
    font-size: 32px;
  }

  .app-header .app-header-container .left-section .logo img {
    width: 120px;
  }
}
