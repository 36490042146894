.open-positions-section {
  background-color: #f3f4f6;
}

.open-positions-wrapper {
  width: 70%;
  margin: auto;
  max-width: 1280px;
  padding: 80px 0px 60px;
}

.open-positions-section .title {
  font-size: 48px;

  text-align: center;
}

.open-positions-section .subtitle {
  text-align: center;
  color: #627386;
  margin-top: 16px;
  font-size: 18px;
}

.open-positions-section .open-positions-wrapper .open-positions-list-title {
  margin-top: 64px;
  color: #7c8d9f;
  text-transform: uppercase;
  letter-spacing: 2.2px;
}

.open-positions-section .open-positions-wrapper .position-item {
  background: #fff;
  padding: 48px;
  border-radius: 10px;
  margin-top: 24px;
}

.open-positions-section .open-positions-wrapper .position-tag {
  display: inline-block;
  padding: 8px;
  background: #e9edfc;
  border-radius: 5px;
  color: #4163eb;
}

.open-positions-section .open-positions-wrapper .position-title {
  font-size: 32px;

  margin: 32px 0px 8px;
}

.open-positions-section .open-positions-wrapper .position-description {
  color: #627386;
  line-height: 28px;
  font-size: 20px;
}

.open-positions-section .open-positions-wrapper .position-botton-wrapper {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.open-positions-section .open-positions-wrapper .position-detail-link img {
  height: 50px;
  width: 50px;
}

.open-positions-section .open-positions-wrapper .position-description .text {
  display: inline;
}

.open-positions-section
  .open-positions-wrapper
  .position-description
  .text::after {
  content: " ";
}

@media screen and (max-width: 1280px) {
  .open-positions-wrapper {
    padding: 64px 16px 32px;
    width: 100%;
  }

  .open-positions-section .title {
    font-size: 24px;
  }

  .open-positions-section .subtitle {
    margin-top: 8px;
  }

  .open-positions-section .open-positions-wrapper .position-item {
    padding: 16px;
    margin-top: 16px;
  }

  .open-positions-section .open-positions-wrapper .position-title {
    font-size: 24px;
    margin-top: 16px;
  }

  .open-positions-section .open-positions-wrapper .position-botton-wrapper {
    display: block;
  }

  .open-positions-section .open-positions-wrapper .position-description {
    margin-bottom: 16px;
  }

  .open-positions-section .open-positions-wrapper .position-detail-link {
    display: block;
    text-align: center;
    background: #f1f4f6;
    border-radius: 6px;
  }
}
